import { MAP_THEME } from "./ChainMap.theme";
type MapLibraries = ("drawing" | "geometry" | "localContext" | "places" | "visualization")[];

export const MAP_API_KEY = "AIzaSyBDFrUPC24n913s9W-gALCpWtXUvXNTKR4";
export const MAP_LIBS: MapLibraries = ["visualization"];

export const MAP_LOADER_ID = "GOOGLE_MAP_LOADER"
export enum MAP_IDS {
    PROFILE = "GOOGLE_MAP_PROFILE",
    CAROUSEL = "GOOGLE_MAP_CAROUSEL"
}

export const MAP_LOADER_OPTIONS = {
    id: MAP_LOADER_ID,
    googleMapsApiKey: MAP_API_KEY,
    libraries: MAP_LIBS,
    language: "en",
    mapIds: [MAP_IDS.PROFILE, MAP_IDS.CAROUSEL],
}

export const MAP_CENTER = {
    lat: 37.7,
    lng: -97.21,
};

export const defaultContainerStyle = {
    width: "100%",
    height: "100%",
    minHeight: 200,
};
export const MAP_DEFAULT_ZOOM = 4;
export interface iMapConfig extends google.maps.MapOptions {
    minZoom: number;
    maxZoom: number;
}
export const MAP_OPTIONS: iMapConfig = {
    zoom: MAP_DEFAULT_ZOOM,
    minZoom: 2,
    maxZoom: 12,
    disableDefaultUI: true,
    styles: MAP_THEME,
    disableDoubleClickZoom: true,
    gestureHandling: "cooperative",
};

export const MAP_GRADIENT = ["rgba(76,132,174, 0)", "rgba(134, 208, 4, 1)"];

export function normZoom(zoom: number) {
    const { maxZoom, minZoom } = MAP_OPTIONS;
    return (zoom - minZoom) / (maxZoom - minZoom);
}

export function getCurve(x: number) {
    const scale = 2;
    return -scale * (x - 1) ** 2 + scale + 0.7;
}

export function getRadius(zoom: number) {
    const normalizedZoom = normZoom(zoom);
    return zoom * getCurve(normalizedZoom);
}
export const trimCanvas = (canvas: HTMLCanvasElement) => {
    const rowBlank = (imageData: any, width: number, y: number) => {
        for (var x = 0; x < width; ++x) {
            if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
        }
        return true;
    }

    const columnBlank = (imageData: any, width: number, x: number, top: number, bottom: number) => {
        for (var y = top; y < bottom; ++y) {
            if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) return false;
        }
        return true;
    }
    const copiedCanv = () => {
        var ctx = canvas.getContext("2d");
        var width = canvas.width;
        if ( ctx ) {
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            var top = 0, bottom = imageData.height, left = 0, right = imageData.width;

            while (top < bottom && rowBlank(imageData, width, top)) ++top;
            while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) --bottom;
            while (left < right && columnBlank(imageData, width, left, top, bottom)) ++left;
            while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) --right;

            var trimmed = ctx.getImageData(left, top, right - left, bottom - top);
            var copy = canvas.ownerDocument.createElement("canvas");
            var copyCtx = copy.getContext("2d");
            copy.width = trimmed.width;
            copy.height = trimmed.height;
            if ( copyCtx ) {
                copyCtx.putImageData(trimmed, 0, 0);
                return copy;
            }

        }
        return canvas;
    }
    return copiedCanv();
}

export const copyImage = (canvas: HTMLCanvasElement) => {
    navigator.clipboard.writeText(canvas.toDataURL());
    console.log("copied");
}

export const appendImage = (data: string) => {
    let image = new Image();
    image.crossOrigin = "anonymous";
    image.src = data;
    const root = document.querySelector(".ChainPage__description");

    if ( root ) {
        root.appendChild(image);
    }
}
interface iCanvasData {
    left: number;
    top: number;
    size: number;
    el: HTMLCanvasElement | HTMLImageElement;
};
export const postChainMapDataURL = (
    querySelector: string,
    callback: (imageData: string) => void,
    debug: boolean = false,
) => {
    const outputDimensions = { width: 620, height: 370 };
    const mapNode = document.querySelector(querySelector) as HTMLElement;
    const canvasTiles = mapNode.querySelectorAll("canvas");
    const mapTiles = mapNode.querySelectorAll("img");
    let loadedImagesCount = 0;
    const imageTilesData: iCanvasData[] = [];

    const getNewCanvasSize = (data: iCanvasData[]) => {
        const minLeft = Math.min.apply(null, data.map(item => item.left));
        const maxLeft = Math.max.apply(null, data.map(item => item.left));

        const minTop = Math.min.apply(null, data.map(item => item.top));
        const maxTop = Math.max.apply(null, data.map(item => item.top));

        const size = Math.max.apply(null, data.map(item => item.size))


        return {
            width: Math.abs(minLeft) + maxLeft + size,
            height: Math.abs(minTop) + maxTop + size,
            shift: {
                left: Math.abs(minLeft),
                top: Math.abs(minTop)
            }
        }
    }

    const getMergedCanvas = (data: iCanvasData[]) => {
        const { width, height, shift } = getNewCanvasSize(data);

        let newCanvas = document.createElement('canvas');
        let context = newCanvas.getContext('2d');

        newCanvas.width = width;
        newCanvas.height = height;

        data.forEach((canvas) => {
            if ( context ) {
                context.beginPath();
                context.drawImage(
                    canvas.el,
                    canvas.left + shift.left,
                    canvas.top + shift.top,
                    canvas.size,
                    canvas.size
                );
            }
        });

        return newCanvas;
    };

    const getCanvasTilesData = (elems: NodeListOf<HTMLCanvasElement>): iCanvasData[] => {
        const tilesData: iCanvasData[] = [];
        Array.from(elems).forEach((canvas) => {
            if ( canvas ) {
                const parentNode = canvas.parentElement;

                if ( parentNode ) {
                    tilesData.push({
                        el: canvas,
                        left: parseInt(parentNode.style.left),
                        top: parseInt(parentNode.style.top),
                        size: parseInt(parentNode.style.width)
                    });
                }
            }
        });

        return tilesData;
    };

    const getOutputCanvas = (canvases: HTMLCanvasElement[]) => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d") as CanvasRenderingContext2D;
        canvas.width = outputDimensions.width;
        canvas.height = outputDimensions.height;
        const scale = 0.85;
        const x = -65;
        const y = -70;

        canvases.forEach(el => {
            context.drawImage(
                el,
                x,
                y,
                el.width * scale,
                el.height * scale,
            );
        });

        return canvas;
    }

    const canvasTilesData = getCanvasTilesData(canvasTiles);

    const imageLoaded = () => {
        loadedImagesCount++;

        if (loadedImagesCount === mapTiles.length) {
            const background = getMergedCanvas(imageTilesData);
            const heatmap = getMergedCanvas(canvasTilesData);
            const canvas = getOutputCanvas([background, heatmap]);
            const base64Canvas = canvas.toDataURL();

            if ( debug ) {
                const newTab = window.open() as any;
                newTab.document.body.innerHTML = `<img src="${base64Canvas}" />`;
            }

            callback(base64Canvas);
        }
    }

    const generateCanvas = () => {
        Array.from(mapTiles).forEach(image => {
            setTimeout(() => {
                const parentNode = image.parentElement as HTMLElement;
                const imgEl = document.createElement('img');
                imgEl.onload = imageLoaded;
                imgEl.crossOrigin = "anonymous";
                imgEl.src = image.src;
                imageTilesData.push({
                    el: imgEl,
                    left: parseInt(parentNode.style.left),
                    top: parseInt(parentNode.style.top),
                    size: parseInt(parentNode.style.width)
                });
            });
        });
    }

    generateCanvas();
};
